import Badge from '@/components/Badge';
import Button from '@/components/Button';
import ConfirmDelete from '@/components/ConfirmDelete';
import Form from '@/components/Form';
import Input from '@/components/Input';
import Modal from '@/components/Modal';
import { Pagination } from '@/components/Pagination';
import Progress from '@/components/Progress';
import Spinner from '@/components/Spinner';
import { ListingTable } from '@/components/Table';
import useConfirm from '@/hooks/useConfirm';
import { useDiscountCodes, useTaskService } from '@/hooks/useDiscounts';
import useEntry from '@/hooks/useEntry';
import useFeedback from '@/hooks/useFeedback';
import useNotifications from '@/hooks/useNotifications';
import useSdk from '@/hooks/useSdk';
import dayjs from '@/util/dayjs';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { CloudArrowDownIcon, PlusIcon, PrinterIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { jsPDF } from 'jspdf';
import { nanoid } from 'nanoid';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import FilterCodesModal from './FilterCodesModal';

const DateDisplay = ({
  date,
  email,
  showEmail = false,
}: {
  date: string | null;
  email?: string;
  showEmail?: boolean;
}) => {
  if (!date) {
    return <XMarkIcon className="w-5 h-5 text-red-500" />;
  }

  const formattedDate = dayjs(date).format('DD.MM.YY');
  return showEmail ? `${formattedDate} ${email || ''}` : formattedDate;
};

const PAGE_SIZE = 10;
export default function CodesTable({ showActions = true }) {
  const { api } = useSdk();
  const { voucherID } = useParams();
  const { confirmUser, ConfirmProvider } = useConfirm();
  const getCodes = useDiscountCodes();
  const [page, setPage] = React.useState(1);
  const [showFilter, setShowFilter] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);

  const form = useForm({
    defaultValues: {
      printState: null,
      onlyPrinted: false,
      printed: {
        from: '',
        to: '',
      },
      issuedState: null,
      onlyIssued: false,
      issued: {
        from: '',
        to: '',
      },
      redeemedState: null,
      onlyRedeemed: false,
      redeemed: {
        from: '',
        to: '',
      },
      dateFrom: '',
      dateTo: '',
    },
  });

  const data = form.watch();
  const filter = useMemo(() => {
    const values: any = {};
    values.size = 9999;
    if (data.printState !== null) values.printed = data.printState;
    if (data.issuedState !== null) values.issued = data.issuedState;
    if (data.redeemedState !== null) values.redeemed = data.redeemedState;

    if (data.onlyPrinted) {
      values.printedFrom = data.printed.from ? dayjs(data.printed.from).format('YYYY-MM-DD') : null;
      values.printedTo = data.printed.to ? dayjs(data.printed.to).format('YYYY-MM-DD') : null;
    }

    if (data.onlyIssued) {
      values.issuedFrom = data.issued.from ? dayjs(data.issued.from).format('YYYY-MM-DD') : null;
      values.issuedTo = data.issued.to ? dayjs(data.issued.to).format('YYYY-MM-DD') : null;
    }

    if (data.onlyRedeemed) {
      values.redeemedFrom = data.redeemed.from ? dayjs(data.redeemed.from, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
      values.redeemedTo = data.redeemed.to ? dayjs(data.redeemed.to, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
    }

    return values;
  }, [data]);

  const activeFilterCount = useMemo(() => {
    let count = 0;
    if (data.printState !== null) count++;
    if (data.issuedState !== null) count++;
    if (data.redeemedState !== null) count++;
    if (data.onlyPrinted && (data.printed.from || data.printed.to)) count++;
    if (data.onlyIssued && (data.issued.from || data.issued.to)) count++;
    if (data.onlyRedeemed && (data.redeemed.from || data.redeemed.to)) count++;
    return count;
  }, [data]);

  const { data: codes, mutate } = getCodes(voucherID, {
    page,
    size: pageSize,
    ...filter,
  });

  const not = useNotifications();
  const { data: discount } = useEntry({
    id: voucherID,
    model: 'discount_campaign',
    swrOptions: { revalidateOnFocus: false },
  });

  function printCodePDF(codesToPrint?: any) {
    const doc = new jsPDF({
      format: [85, 54],
      orientation: 'landscape',
    });
    doc.setFontSize(10);
    codesToPrint?.forEach((code, i) => {
      doc.text(code.code, 85 / 2, 54 / 2, { align: 'center' });
      if (i !== codesToPrint.length - 1) {
        doc.addPage();
      }
    });
    confirmUser('Sollen die Codes als gedruckt markiert werden?', 'Ja', 'Nein')
      .then(async () => {
        //TODO
        await Promise.all(codesToPrint.map(async (code) => setCodePrinted(code)));
        not.emit({ type: 'success', message: 'Codes als gedruckt markiert' });
        mutate();
      })
      .catch(() => not.emit({ type: 'success', message: 'Codes nicht als gedruckt markiert' }));

    doc.output('dataurlnewwindow');
  }

  function printCodes(codesToPrint?: any[]) {
    const iframe = document.createElement('iframe');

    iframe.src =
      window.location.pathname + (codesToPrint ? `/print?code=${codesToPrint.map((c) => c.id).join(',')}` : '/print');
    document.body.appendChild(iframe);

    const handleIframeMessage = (e: MessageEvent) => {
      if (e.data.type === 'startPrint') iframe.contentWindow?.print();

      if (e.data.type === 'afterPrint') {
        document.body.removeChild(iframe);
        iframe.contentWindow?.removeEventListener('message', handleIframeMessage);

        confirmUser('Sollen die Codes als gedruckt markiert werden?', 'Ja', 'Nein')
          .then(async () => {
            //TODO
            await Promise.all(codesToPrint.map(async (code) => setCodePrinted(code)));
            not.emit({ type: 'success', message: 'Codes als gedruckt markiert' });
            mutate();
          })
          .catch(() => not.emit({ type: 'success', message: 'Codes nicht als gedruckt markiert' }));
      }
    };

    iframe.contentWindow?.addEventListener('message', handleIframeMessage);
  }

  const { withFeedback } = useFeedback();
  function exportData(codes: any[]) {
    withFeedback(
      async () => {
        const formatDate = (date: string) => {
          return date ? dayjs(date).format('DD.MM.YY') : '';
        };
        const csvData = codes.map(({ hectorConfig, ...code }) => ({
          id: code.id,
          Code: code.code,
          'E-Mail': code.email,
          'Erstellt am': dayjs(code.created).format('DD.MM.YY'),
          'Gedruckt am': formatDate(code.printed),
          'Ausgegeben am': formatDate(code.issued),
          'Eingelöst am': formatDate(code.redeemed),
          'Bezahlt am': code.paid ? JSON.stringify(code.paid) : 'Nein',
          'Name der Kampagne': discount.name,
          'Kampagne aktiv': discount.active ? 'Ja' : 'Nein',
          Tags: discount.tags?.map((tag: any) => tag._entryTitle).join(', '),
          'Einlösbar ab': formatDate(discount.dateStart),
          'Einlösbar bis': formatDate(discount.dateEnd),

          'Verkauf ab': formatDate(discount.sellingStart),
          'Verkauf bis': formatDate(discount.sellingEnd),
          'Anzahl der Codes begrenzt': discount.limitedCodes,
          'Hector Einstellungen': JSON.stringify(discount.hectorConfig),
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'codes.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();

        await Promise.all(
          codes.map(async (code) => {
            const entry = await api.entry('discount_code', code.id);
            entry.issued = dayjs().toISOString();
            return entry.save();
          }),
        );
      },
      {
        success: 'Codes erfolgreich exportiert',
        error: 'Fehler beim Exportieren',
      },
    );
  }
  async function setCodePrinted(code: { id: string }) {
    const entry = await api.entry('discount_code', code.id);
    entry.printed = dayjs().toISOString();
    entry.issued = dayjs().toISOString();
    await entry.save();
  }

  const [showCreateMore, setShowCreateMore] = React.useState(false);

  return (
    <div>
      <ConfirmProvider />

      {discount?.activatedForSale && <Badge>Für Verkaufsgutscheine können keine Codes manuell erzeugt werden</Badge>}
      {!discount?.activatedForSale && discount?.remainingCodes === -1 && <Badge>Code ist unbegrenzt einlösbar</Badge>}

      <div className="flex gap-3 justify-between items-center my-3">
        <div className="flex gap-2">
          <Button $secondary onClick={() => setShowFilter(true)}>
            <FunnelIcon className="w-4 h-4 text-gray-400 mr-2" />
            Codes filtern
            {activeFilterCount > 0 && (
              <div className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-indigo-500 rounded-full">
                {activeFilterCount}
              </div>
            )}
          </Button>
          {activeFilterCount > 0 && (
            <Button.Action
              tooltip="Filter zurücksetzen"
              onClick={() => {
                form.reset({
                  printState: null,
                  onlyPrinted: false,
                  printed: { from: '', to: '' },
                  issuedState: null,
                  onlyIssued: false,
                  issued: { from: '', to: '' },
                  redeemedState: null,
                  onlyRedeemed: false,
                  redeemed: { from: '', to: '' },
                  dateFrom: '',
                  dateTo: '',
                });
              }}
            >
              <XMarkIcon className="h-5 w-5 text-gray-400" />
            </Button.Action>
          )}
        </div>

        <FilterCodesModal headline="Codes filtern" show={showFilter} form={form as any} setShow={setShowFilter} />

        {showDeleteModal && <DeleteModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />}
        {showActions && (
          <span className="gap-3 inline-flex rounded-md ">
            {false && codes?.data.every((code) => !code.redeemed) && (
              <Button $danger onClick={() => setShowDeleteModal(true)}>
                <TrashIcon className="h-4 w-4 " aria-hidden="true" /> Aktion mit allen Codes löschen
              </Button>
            )}
            {codes?.meta.total > 0 && (
              <Button $secondary onClick={() => exportData(codes?.data)}>
                <CloudArrowDownIcon className="h-4 w-4 text-gray-400 mr-2" aria-hidden="true" /> CSV herunterladen
              </Button>
            )}
            {codes?.meta.total > 0 && (
              <Button $secondary onClick={() => printCodePDF(codes?.data)}>
                <PrinterIcon className="h-4 w-4 text-gray-400 mr-2" aria-hidden="true" /> Codes drucken
              </Button>
            )}

            {!discount?.activatedForSale && discount?.remainingCodes !== -1 && (
              <Button onClick={() => setShowCreateMore(true)}>
                <PlusIcon className="h-4 w-4 text-gray-400 mr-2 " aria-hidden="true" /> Codes hinzufügen
              </Button>
            )}
          </span>
        )}
      </div>
      <ListingTable
        header={['Code', 'Erstellt', 'Gedruckt', 'Exportiert', 'Eingelöst', 'Bezahlt', '']}
        data={codes?.data}
        cell={(code) => {
          return [
            <span className="font-bold">{code.code}</span>,
            dayjs(code.created).format('DD.MM.YY'),
            <DateDisplay date={code.printed} />,
            <DateDisplay date={code.issued} />,
            <DateDisplay date={code.redeemed} email={code.email} showEmail={true} />,
            <DateDisplay date={code.paid} email={code.email} showEmail={true} />,

            showActions && (
              <Button.Action tooltip="Code ausdrucken" onClick={() => printCodes([code])}>
                <PrinterIcon className="h-5 w-5 cursor-pointer text-gray-400" />
              </Button.Action>
            ),
          ];
        }}
      />
      <Pagination total={codes?.meta.total} count={PAGE_SIZE} hideTextInfo={false} value={page} onChange={setPage} />
      <div className="flex gap-3 justify-end mt-3">
        <Modal open={showCreateMore} onClose={() => setShowCreateMore(false)}>
          <div className="flex gap-3 min-w-[50vw] justify-between">
            <h3>Weitere Codes anlegen</h3>
            <Button.Action onClick={() => setShowCreateMore(false)}>
              <XMarkIcon className="h-5 w-5 cursor-pointer text-gray-400" />
            </Button.Action>
          </div>
          <AddCodesModal
            forSale={discount?.activatedForSale}
            random={discount?.randomCodes}
            presetCode={discount?.code}
            onDone={() => {
              setShowCreateMore(false);
              mutate();
            }}
          />
        </Modal>
      </div>
    </div>
  );
}

function AddCodesModal({
  onDone,
  random,
  presetCode,
  forSale,
}: {
  onDone: () => void;
  random?: boolean;
  forSale: boolean;
}) {
  const [codes, setCodes] = React.useState([]);
  const { shortID, voucherID } = useParams();
  const [count, setCount] = React.useState(1);
  const [code, setCode] = React.useState('');

  const debounce = useRef(null);
  useEffect(() => {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      if (code !== '' && count > 0) {
        setCodes(
          Array(Number(count))
            .fill(0)
            .map(() => `${code}${random ? '-' + nanoid(5) : ''}`),
        );
      }
    }, 500);
  }, [code, count, random]);
  const not = useNotifications();

  const { taskStatus, setSettings } = useTaskService();
  async function addCodes() {
    setSettings((settings) => ({
      ...settings,
      url: 'hec.discount-create',
      params: {
        shortID,
        discountCampaignID: voucherID,
        count,
        code,
      },
      callback: () => {
        not.emit({ type: 'success', message: 'Discount created' });
        setTimeout(() => {
          onDone();
        }, 1000);
      },
      onError: (error) => {
        not.emit({ type: 'error', message: error.message });
      },
    }));
  }
  const monitorProgress = useMemo(() => {
    if (taskStatus?.data) {
      const stat = taskStatus?.data?.[taskStatus?.data?.length - 1]?.match(/(\d+)\/(\d+)/);
      return [stat?.[1] || 0, stat?.[2] || 0];
    }
    return [0, 0];
  }, [taskStatus]);

  return (
    <div>
      <div className="flex gap-3 mb-3">
        <div className="w-full">
          <Form.Item $first $dense>
            <Form.Item.Label>Anzahl</Form.Item.Label>
            <Input label="Anzahl" type="number" value={count} onChange={(e: any) => setCount(+e.target.value)} />
          </Form.Item>
        </div>
        <div className="w-full">
          <Form.Item $first $dense>
            <Form.Item.Label>Code Präfix</Form.Item.Label>
            {random ? (
              <Input label="Code" value={code} onChange={(e: any) => setCode(e.target.value)} type="text" />
            ) : (
              <>
                <Input
                  label="Code"
                  disabled
                  value={presetCode}
                  onChange={(e: any) => setCode(e.target.value)}
                  type="text"
                />
                <small>Randomisierte Codes sind deaktiviert</small>
              </>
            )}
          </Form.Item>
        </div>
      </div>

      {monitorProgress[1] > 0 && (
        <div className="my-6">
          <span className="text-md mb-2 flex gap-3 items-center">
            {monitorProgress[0]} von {monitorProgress[1]} Codes angelegt <Spinner />
          </span>
          <Progress max={monitorProgress[1] || 0} value={monitorProgress[0] || 0} />
        </div>
      )}

      <div className="flex justify-end mt-3">
        <Button onClick={() => addCodes()} loading={taskStatus?.data} $primary $disabled={random && codes.length === 0}>
          Codes anlegen
        </Button>
      </div>
    </div>
  );
}

export function DeleteModal({ open, onClose }) {
  const { voucherID: discountID } = useParams();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { api } = useSdk();
  const { taskStatus, monitor, setSettings } = useTaskService();

  console.log(taskStatus.data, monitor.data);

  const monitorProgress = useMemo(() => {
    if (taskStatus?.data) {
      const stat = taskStatus?.data?.[taskStatus?.data?.length - 1]?.match(/(\d+)\/(\d+)/);
      return [stat?.[1] || 0, stat?.[2] || 0];
    }
    return [0, 0];
  }, [taskStatus]);

  async function deleteAll() {
    setIsDeleting(true);
    setSettings({
      url: 'hec.discount-delete',
      params: {
        shortID: api.shortID,
        refreshInterval: 1000,
        discountCampaignID: discountID,
      },
      callback: () => {
        notifications.emit({
          type: 'success',
          message: 'Codes erfolgreich gelöscht',
        });
        setIsDeleting(false);
        navigate('..');
      },
      onError: (res) => {
        const errorMessage = res.find((log) => log.toLowerCase().includes('error'));
        notifications.emit({
          type: 'error',
          message:
            errorMessage === 'Error: cannot_be_deleted_because_codes_were_used'
              ? 'Codes können nicht gelöscht werden, weil bereits einer eingelöst / exportiert wurden'
              : errorMessage,
        });

        onClose();
        setIsDeleting(false);
      },
    });
  }

  return (
    <ConfirmDelete
      isPending={isDeleting}
      title="Aktion mit allen Codes wirklich löschen?"
      deleteLabel="Löschen"
      onDelete={deleteAll}
      onClose={onClose}
      open={open}
    >
      {isDeleting ? (
        <div className="flex gap-3 items-center">
          {monitorProgress[1] > 0 && (
            <div className="my-6">
              <span className="text-md mb-2 flex gap-3 items-center">
                {monitorProgress[0]} von {monitorProgress[1]} Codes gelöscht <Spinner />
              </span>
              <Progress max={monitorProgress[1] || 0} value={monitorProgress[0] || 0} />
            </div>
          )}
        </div>
      ) : (
        'Diese Aktion kann nicht rückgängig gemacht werden'
      )}
    </ConfirmDelete>
  );
}
